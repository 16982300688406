export const list = "{ merchants { items { merchantUid, merchantName, isSystem }, totalRowCount } }"

export const query = uid => {
  return `{ merchant(merchantUid: "${uid}") { apiKey, merchantUid, isSystem, merchantName, interchange {achBasis, achFixed, cardBasis, cardFixed}, basis {achBasis, achFixed, cardBasis, cardFixed}, fixed {achBasis, achFixed, cardBasis, cardFixed} } }`
}

export const addAppleCredentials = creds => {
  return `mutation { addIOSCredentials(nativeCreds: { uid: "${creds.uid}", cfBundleIdentifier: "${creds["cf_bundle_identifier"]}", appleTeamId: "${creds["apple_team_id"]}", apiKey: "${creds.apiKey}"} ) { updatedIOS { credentials } } } `
}

export const addAndroidCredentials = creds => {
  return `mutation { addAndroidCredentials(nativeCreds: {uid: "${creds.uid}", apkPackageName: "${creds["apk_package_name"]}", apkDigestProd: "${creds["apk_digest_prod"]}", apkDigestDebug: "${creds["apk_digest_debug"]}", apiKey: "${creds.apiKey}"} ) { updatedAndroid { credentials } } }`
}

export const deleteAppleCredentials = creds => {
  return `mutation { deleteIOSCredentials(nativeCreds: {uid: "${creds.uid}", cfBundleIdentifier: "${creds["cf_bundle_identifier"]}", appleTeamId: "${creds["apple_team_id"]}", apiKey: "${creds.apiKey}"}) { updatedIOS { credentials } } }`
}

export const deleteAndroidCredentials = creds => {
  return `mutation { deleteAndroidCredentials(nativeCreds: { uid: "${creds.uid}", apkPackageName: "${creds["apk_package_name"]}", apkDigestProd: "${creds["apk_digest_prod"]}", apkDigestDebug: "${creds["apk_digest_debug"]}", apiKey: "${creds.apiKey}"}) { updatedAndroid { credentials } } }`
}