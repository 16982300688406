export const list = (userPool, merchant) => {
  return `{ users(userPoolId: "${userPool}"${merchant ? `, merchant: "${merchant}"` : ''}) { username, enabled, userStatus, email, fullName, phone } }`
}

export const create = (userPool, firstName, lastName, email, phone, merchant) => {
  return `mutation { createUser(userPoolId: "${userPool}", firstName: "${firstName}", lastName: "${lastName}", email: "${email}"${phone ? `, phone: "+1${phone}"` : ''}${merchant ? `, merchant: "${merchant}"` : ''}) { createdUser { username, enabled, userStatus, email } } }`
}

export const deleteUser = (userPool, username) => {
  return `mutation { deleteUser(userPoolId: "${userPool}", username: "${username}") { deleteResponse { username, message } } }`
}