import React, {createContext, lazy, Suspense, useCallback, useEffect, useState} from 'react'

import {BooksHooks, ModalSpinner, NotFound, openSpinner, usePortalNotification} from '@paytheory/pay-theory-ui'

import {generateMenu} from './views/util'

import './App.css'

import {Navigate, Route, Routes} from 'react-router-dom'

import {withAuthenticator} from "@aws-amplify/ui-react";

import * as Login from './components/LoginComponents'

import * as network from './network'

import * as ROUTES from './constants/routes'

const Font = lazy(() => import ('@paytheory/pay-theory-ui/dist/Font'))

// const Unauthorized = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

// const NotFound = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

const ManageAdmins = lazy(() => import ('./views/ManageAdmins'))

const ManageMerchants = lazy(() => import ('./views/ManageMerchants'))

const MerchantDetail = lazy(() => import ('./views/MerchantDetail'))

export const AppContext = createContext()

export const App = ({user, signOut}) => {
    const [accessToken, setAccessToken] = useState(false)
    const [idToken, setIdToken] = useState(false)
    const [merchantUID, setMerchantUID] = useState(false)
    const [merchant, setMerchant] = useState({})
    const [connected, setConnected] = useState(false)
    const [websocket, setWebsocket] = useState(false)
    const typekit = process.env.REACT_APP_TYPEKIT

    const {
        ErrorMessage, SuccessMessage
    } = usePortalNotification();

    useEffect(() => {
        if (user && !accessToken) {
            let userAccessToken = user.signInUserSession?.accessToken.jwtToken
            let userIdToken = user.signInUserSession?.idToken.jwtToken
            let merchant = user.signInUserSession?.idToken.payload['custom:merchant_uid']
            setIdToken(userIdToken)
            setAccessToken(userAccessToken)
            setMerchantUID(merchant)
        }
    }, [user, accessToken])

    useEffect(() => {
        if (accessToken && idToken && connected === false) {
            openSpinner()
            let socket = network.createSocket(accessToken, idToken, setConnected)
            setWebsocket(socket)
        }
    }, [accessToken, idToken, connected])

    const customSignOut = useCallback(() => {
        setAccessToken(false)
        setIdToken(false)
        setConnected(false)
        if (websocket) websocket.dispose()
        setWebsocket(false)
        signOut()
    }, [websocket])


    useEffect(() => {
        const minutesTillTimeout = 5
        const timeout = minutesTillTimeout * 60000
        const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown']

        let t = setTimeout(signOut, timeout)

        const updateTimeout = () => {
            clearTimeout(t)
            t = setTimeout(signOut, timeout)
        }

        actions.forEach((action) => {
            document.addEventListener(action, updateTimeout, {
                capture: false, passive: true
            })
        })

        return () => {
            actions.forEach((action) => {
                document.removeEventListener(action, updateTimeout)
            })
            clearTimeout(t)
        }
    }, [signOut])

    return (<div className="spinner-wrapper">
        <div className="modal-wrapper">
            <BooksHooks.Context.Menu.Provider value={generateMenu()}>
                <AppContext.Provider value={{
                    websocket, connected, merchantUID, signOut: customSignOut, ErrorMessage, SuccessMessage
                }}>
                    <Suspense fallback={<ModalSpinner on/>}>
                        <Routes>
                            <Route path="/" element={<Navigate replace to={ROUTES.HOME}/>}/>
                            <Route
                                exact
                                path={ROUTES.MANAGE_ADMINS}
                                element={<ManageAdmins/>}
                            />
                            <Route
                                exact
                                path={ROUTES.MANAGE_MERCHANTS}
                                element={<ManageMerchants/>}
                            />
                            <Route
                                exact
                                path={ROUTES.MERCHANT_DETAIL}
                                element={<MerchantDetail/>}
                            />
                            <Route
                                path='*'
                                element={<NotFound/>}
                            />
                        </Routes>
                        <Font typekit={typekit}/>
                    </Suspense>
                    <ModalSpinner/>
                </AppContext.Provider>
            </BooksHooks.Context.Menu.Provider>
        </div>
    </div>)
}

export default withAuthenticator(App, {
    includeGreetings: false,
    loginMechanisms: ['email'],
    components: {
        Header: Login.Header,
        SignIn: {
            Header: Login.SignInHeader
        },
    }
});
